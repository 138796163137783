import { registerLinkResolver } from 'gatsby-source-prismic-graphql'
import { linkResolver } from './src/utils/link-resolver'

import element from './wrap-page-element'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import 'typeface-raleway'
import 'typeface-lobster-two'
import 'typeface-montserrat'

registerLinkResolver(linkResolver);

export const wrapPageElement = element