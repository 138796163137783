// -- The Link Resolver
// This function will be used to generate links to Prismic documents
// As your project grows, you should update this function according to your routes

export function linkResolver(doc) {
  // Route for blog posts
  let prefix = ''
  if (doc && doc.lang) {
    prefix = `/${doc.lang.substring(0, 2)}`
  }

  switch (doc.type) {
    case 'blogpage':
      return `${prefix}/blog/`
    case 'newspage':
      return `${prefix}/news/`
    case 'shop':
      return `${prefix}/shop/`
    case 'contact':
      return `${prefix}/contact/`
    default:
      return '/'
  }
}
