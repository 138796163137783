import React from 'react'
import { ThemeProvider, defaultTheme } from 'sancho'

const sansFont = `Raleway, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`;

const fonts = {
    sans: sansFont,
    base: sansFont,
    monospace: `SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace`
};

const theme = {
    ...defaultTheme,
    fonts,
}

export default ({ element }) => {
    return <ThemeProvider theme={theme}>{element}</ThemeProvider>
}