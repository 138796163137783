// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js": () => import("../node_modules/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-templates-news-post-js": () => import("../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-templates-blog-post-js": () => import("../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-en-js": () => import("../src/pages/blog.en.js" /* webpackChunkName: "component---src-pages-blog-en-js" */),
  "component---src-pages-blog-fr-js": () => import("../src/pages/blog.fr.js" /* webpackChunkName: "component---src-pages-blog-fr-js" */),
  "component---src-pages-contact-en-js": () => import("../src/pages/contact.en.js" /* webpackChunkName: "component---src-pages-contact-en-js" */),
  "component---src-pages-contact-fr-js": () => import("../src/pages/contact.fr.js" /* webpackChunkName: "component---src-pages-contact-fr-js" */),
  "component---src-pages-index-en-js": () => import("../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-fr-js": () => import("../src/pages/index.fr.js" /* webpackChunkName: "component---src-pages-index-fr-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-en-js": () => import("../src/pages/news.en.js" /* webpackChunkName: "component---src-pages-news-en-js" */),
  "component---src-pages-news-fr-js": () => import("../src/pages/news.fr.js" /* webpackChunkName: "component---src-pages-news-fr-js" */),
  "component---src-pages-shop-en-js": () => import("../src/pages/shop.en.js" /* webpackChunkName: "component---src-pages-shop-en-js" */),
  "component---src-pages-shop-fr-js": () => import("../src/pages/shop.fr.js" /* webpackChunkName: "component---src-pages-shop-fr-js" */)
}

